import  Images  from "./views/images/Images";
import { Home } from "./components/Home";

const AppRoutes = [
  {
    path: '/Event/:id',
    element: <Home />
  },
  {
    path: '/Images',
    element: <Images />
  }
];

export default AppRoutes;
