import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {
    TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './Welcome.css'
import imageLogo from '../../icons/logo.png'

const Welcome = () => {

    // Get ID from URL
    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState({
        'eventId': '',
        'eventName': '',
        'eventDescription': '',
        'eventEventDateTime': '',
        'creator': '',
        'images': []
    })
    const navigate = useNavigate()

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const api = 'api/Event/' + params.id;

            const response = await fetch(api); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Greška prilikom komunikacije sa serverom. Proverite da li ste na istoj mreži.');
            }

            const data = await response.json();

            console.log("data");
            console.log(data);

            //const images = [];

            //if (data !== null) {
            //    data.images.map((image) => {

            //        console.log("321");
            //        var i = {
            //            'Id': image.id,
            //            'Name': image.name,
            //            'EventId': image.eventId,
            //            'Creator': image.creator,
            //            'ImageDate': image.imageDate,
            //            'Stream': 'data:image/jpeg;base64,' + image.stream
            //        }
            //        images.push(i)
            //    })
            //}

            //console.log("SLIKEEE");
            //console.log(images);

            const res = {
                'eventId': data.eventDB.id,
                'eventName': data.eventDB.name,
                'eventDescription': data.eventDB.description,
                'eventEventDateTime': data.eventDB.eventDateTime,
                'creator': '',
                'images': []
            }

            setResponse(res);

            setLoading(false);
        } catch (error) {
            setError("Desila se greška: " + error.message);
            setLoading(false);
        }
    };

    const handleResponseChange = (event) => {
        const { name, value } = event.target;

        setResponse((prevState) => ({
            ...prevState,
            [name]: value
        }));
        console.log(response);
    }

    const handleSubmit = (event) => {

        console.log(response);

        if (response.creator === null ||
            response.creator === '') {
            console.log("Greska prilikom logovanja");

            setError("Morate uneti kreatora!");
            setLoading(false);

            return
        }

        event.preventDefault();

        //const user = porudzbina.user
        //console.log("Ulogovan je user: " + user);

        //const u = users.find(uu => uu.Id == porudzbina.user);

        //porudzbina.userName = u.Name;

        navigate('/Images', { state: { response: response } });
    }

    return (
        <div className="App1">
            <div className="appAside" />
            <div className="appForm">


                <div className="formTitle centerB">
                    {response.eventName === '' ? null :
                        <p className="formTitleLink"> {response.eventName} </p>
                    }
                </div>

                <div className="formTitle">
                    <p> Život ne merimo godinama već brojem nezaboravnih trenutaka provedenih sa najdražim ljudima </p>
                    <p > Neka ova noć bude magična uspomena koje ćemo se zauvek sećati </p>
                    <p > Hvala Vam što ćete ulepšati naš događaj! </p>
                    <p > 15.6.2024. </p>

                </div>
                <div className="formCenter">
                    <form className="formFields" onSubmit={handleSubmit}>

                        <div className="formField">
                            {loading ? (
                                <p>Sačekajte...</p>
                            ) : error ? (
                                <p>Greška: {error}</p>
                            ) : (
                                <TextField fullWidth
                                            id="outlined-required"
                                            label="Kreator"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "0.9em",
                                                    fontWeight: 1000,
                                                    color: "black"
                                                }
                                            }}
                                            name="creator"
                                            onChange={(e) => handleResponseChange(e)}
                                            required
                                            value={response.creator}
                                            inputProps={{
                                                style: {
                                                    fontSize: "0.9em",
                                                    color: "black",
                                                }
                                            }}
                                        />
                            )}
                        </div>

                        <div className="centerB">
                            <button className="formFieldButton">Dalje</button>{" "}
                        </div>

                        <div className="centerB logo">
                            <img height="150px" src={require('../../icons/logo.png')} />
                        </div>
                        <div className="centerB">
                            <label className="formFieldLabel" htmlFor="email">
                                CleanCodeSirmium
                            </label>
                        </div>
                        <div className="centerB">
                            <label className="formFieldLabel" htmlFor="email">
                                tel: +381/64-44-20-296
                            </label>
                        </div>
                        <div className="centerB">
                            <label className="formFieldLabel" htmlFor="email">
                                email: cleancodesirmium@gmail.com
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default Welcome;