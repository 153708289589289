import React, { Component } from 'react';
import Welcome from '../views/welcome/Welcome';

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (
          <div>
              <Welcome />
          </div>
    );
  }
}
