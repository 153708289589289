import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Photo, PhotoAlbum } from "react-photo-album";
import axios from "axios"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import 'react-notifications/lib/notifications.css';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { Typography, AppBar, Button, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Toolbar, Container, CircularProgress } from '@mui/material'
import { PhotoCamera } from '@mui/icons-material'
//import useStyles from './styles'


import './Images.css'

const Images = () => {
    const navigate = useNavigate()
    let location = useLocation();
    const ref = useRef(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [osnovno, setOsnovno] = useState(location.state.response);

    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);

    const [allPages, setAllPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        try {
            setLoading(true);
            const api = 'api/Event/AllImages/' + osnovno.eventId;

            const r = await fetch(api); // Replace with your API endpoint
            if (!r.ok) {
                throw new Error('Greška prilikom komunikacije sa serverom. Proverite da li ste na istoj mreži.');
            }

            console.log("NEMANJA CAR")

            const data = await r.json();

            const images = [];

            if (data !== null) {
                data.images.map((image) => {

                    var i = {
                        'Id': image.id,
                        'Name': image.name,
                        'EventId': image.eventId,
                        'Creator': image.creator,
                        'ImageDate': image.imageDate,
                        'Stream': 'data:image/jpeg;base64,' + image.stream
                    }
                    images.push(i)
                })
            }

            console.log("SLIKEEE");
            console.log(images);

            setOsnovno((staro) => ({
                ...staro,
                "images": images
            }));

            const apiTotalPage = 'api/Images/TotalPage/' + osnovno.eventId;

            const rTotalPage = await fetch(apiTotalPage); // Replace with your API endpoint
            if (!rTotalPage.ok) {
                throw new Error('Greška prilikom komunikacije sa serverom. Proverite da li ste na istoj mreži.');
            }


            const dataTotalPage = await rTotalPage.json();

            console.log("dataTotalPage")
            console.log(dataTotalPage)

            const pages = [];

            for (var i = 1; i <= dataTotalPage.totalPageNumber; i++) {
                pages.push(i);
            }

            setAllPages(pages);
            setCurrentPage(1);

            setLoading(false);

        } catch (error) {
            setError("Desila se greška: " + error.message);
            setLoading(false);
        }
    };

    //const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48, 1080, 640, 384, 256, 128, 96, 64, 48, 384, 256];
    const breakpoints = [1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080];

    //const resolution = [
    //    { width: 1080, height: 780 },
    //    { width: 1080, height: 1620 },
    //    { width: 1080, height: 720 },
    //    { width: 1080, height: 720 },
    //    { width: 1080, height: 1620 },
    //    { width: 1080, height: 607 },
    //    { width: 1080, height: 608 },
    //    { width: 1080, height: 720 },
    //    { width: 1080, height: 1549 },
    //    { width: 1080, height: 720 },
    //    { width: 1080, height: 694 },
    //    { width: 1080, height: 1620 },
    //    { width: 1080, height: 720 },
    //    { width: 1080, height: 1440 },
    //    { width: 1080, height: 1620 },
    //    { width: 1080, height: 810 },
    //    { width: 1080, height: 595 },
    //    { width: 1080, height: 160 },
    //    { width: 1080, height: 810 },
    //    { width: 1080, height: 720 },
    //    { width: 1080, height: 1440 },
    //]
    const resolution = [
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
        { width: 1080, height: 780 },
    ]

    function napraviSlike()
    {
        const slike = [];
        osnovno.images.map((image, index) => {
            const slika = {
                src: image.Stream,
                width: resolution[index].width,
                height: Math.round((resolution[index].height / resolution[index].width) * breakpoints[index]),
                image: image,
                key: image.Id
            }
            slike.push(slika);
        })

        return slike;
    }

    async function reset() {
        setLoading(true);

        setCurrentImage(null)
        setSelectedImage([])

        const api = 'api/Event/AllImages/' + osnovno.eventId;

        const r = await fetch(api); // Replace with your API endpoint
        if (!r.ok) {
            throw new Error('Greška prilikom komunikacije sa serverom. Proverite da li ste na istoj mreži.');
        }

        const data = await r.json();

        const images = [];

        if (data !== null) {
            data.images.map((image) => {

                console.log("321");
                var i = {
                    'Id': image.id,
                    'Name': image.name,
                    'EventId': image.eventId,
                    'Creator': image.creator,
                    'ImageDate': image.imageDate,
                    'Stream': 'data:image/jpeg;base64,' + image.stream
                }
                images.push(i)
            })
        }

        console.log("SLIKEEE");
        console.log(images);

        setOsnovno((staro) => ({
            ...staro,
            "images": images
        }));

        const apiTotalPage = 'api/Images/TotalPage/' + osnovno.eventId;

        const rTotalPage = await fetch(apiTotalPage); // Replace with your API endpoint
        if (!rTotalPage.ok) {
            throw new Error('Greška prilikom komunikacije sa serverom. Proverite da li ste na istoj mreži.');
        }


        const dataTotalPage = await rTotalPage.json();

        console.log("dataTotalPage")
        console.log(dataTotalPage)

        const pages = [];

        for (var i = 1; i <= dataTotalPage.totalPageNumber; i++) {
            pages.push(i);
        }

        setSelectedImage(null);
        setAllPages(pages);
        setCurrentPage(1);
        setLoading(false);
    }

    const handleSubmit = async (event) => {

        console.log("AAAAAAAAAAAAAAAAA");
        console.log(selectedImage);

        const data = new FormData();

        selectedImage.map(image => {
            data.append("images", image);
        })
        data.append("eventId", osnovno.eventId);
        data.append("Creator", osnovno.creator);


        try {
            const aaa = await axios.post("api/Images/Upload", data)

            NotificationManager.success('Uspešno ste dodali slike!', 'Hvala!', 1500);

            reset();
        }
        catch (ex) {
            NotificationManager.error('Greška prilikom dodavanja slika. Pokušajte ponovo.', 'Greška', 2000);
        }


    }

    const openImage = (image) => {
        setOpen(true);

        const slika = {
            autor: image.Creator,
            src: image.Stream,
            width: resolution[0].width,
            height: Math.round((resolution[0].height / resolution[0].width) * breakpoints[0]),
            image: image,
            key: image.Id
        }

        setCurrentImage([slika]);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const onChangeSelectedImage = function (e) {
        const listImages = []

        e.map(aa => {
            listImages.push(aa);
        })

        setSelectedImage(listImages);
    }

    const handleChangePagination = async (e, p) => {
        setCurrentPage(p);
        //_DATA.jump(p);

        setCurrentImage(null)
        setSelectedImage([])

        const api = 'api/Event/' + osnovno.eventId + "/" + p;

        console.log("p")
        console.log(api)

        const r = await fetch(api); // Replace with your API endpoint
        if (!r.ok) {
            throw new Error('Greška prilikom komunikacije sa serverom. Proverite da li ste na istoj mreži.');
        }

        const data = await r.json();

        const images = [];

        if (data !== null) {
            data.images.map((image) => {

                console.log("321");
                var i = {
                    'Id': image.id,
                    'Name': image.name,
                    'EventId': image.eventId,
                    'Creator': image.creator,
                    'ImageDate': image.imageDate,
                    'Stream': 'data:image/jpeg;base64,' + image.stream
                }
                images.push(i)
            })
        }

        console.log("SLIKEEE");
        console.log(images);

        setOsnovno((staro) => ({
            ...staro,
            "images": images
        }));
    };


    return (            


            <div className="App1">
                <div className="appForm1">

                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            const email = formJson.email;
                            console.log(email);
                            handleClose();
                        },
                    }}
                >
                    <DialogTitle>Autor: {currentImage === null ? null : currentImage[0].autor}</DialogTitle>
                    <DialogContent>
                        {currentImage === null ? null : 
                            <PhotoAlbum
                                layout="rows"
                                photos={currentImage}
                                renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                                    <a
                                        onClick={() => openImage(photo.image)}
                                        width={photo.width}
                                        height={photo.width}
                                        key={photo.key}
                                    >
                                        {renderDefaultPhoto({ wrapped: true })}
                                    </a>
                                )}
                            />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Zatvori</Button>
                    </DialogActions>
                </Dialog>
                <div>
                    {/* Header */}

                    <div className="formTitle centerB">
                        <p className="formTitleLink"> Dodajte slike u galeriju</p>
                    </div>

                    {/* Conditionally render the selected image if it exists */}
                    {selectedImage && (
                        <div className="centerB">
                            {/* Display the selected image */}
                            
                            <br /> <br />
                            {/* Button to remove the selected image */}
                            <button className="formFieldButton1" onClick={() => setSelectedImage(null)}>Obriši</button>
                            <button className="formFieldButton1" onClick={() => handleSubmit()}>Dodaj</button>
                        </div>
                    )}

                    <br />

                    {/* Input element to select an image file */}
                    <input
                        type="file"
                        name="myImage"
                        multiple="multiple"
                        style={{ color: "black" }}
                        // Event handler to capture file selection and update the state
                        onChange={(event) => {
                            console.log(event.target.files[0]); // Log the selected file
                            onChangeSelectedImage([...event.target.files]); // Update the state with the selected file
                        }}
                    />
                </div>

                <br />
                <br />
                <br />

                {loading ? (
                    <div className="formTitle centerB">
                        <p>Sačekajte...</p>
                    </div>
                ) : error ? (
                    <div className="formTitle centerB">
                        <p>Greška: {error}</p>
                    </div>
                    ) : (
                        <PhotoAlbum
                        layout="rows"
                        photos={napraviSlike()}
                        renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                            <a
                                onClick={() => openImage(photo.image)}
                                width={photo.width}
                                height={photo.width}
                                key={photo.key}
                            >
                                {renderDefaultPhoto({ wrapped: true })}
                            </a>
                        )}
                    />) }

                <br />
                <br />

                <div className="centerB">
                    <Stack spacing={2}>
                        <Pagination
                            count={allPages.length}
                            color="primary"
                            page={currentPage}
                            onChange={handleChangePagination}
                        />
                    </Stack>
                </div>
            </div>

            <NotificationContainer />
            </div>
    );
}
export default Images;